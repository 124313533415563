import React from 'react';
import { useSelect } from 'downshift';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import { ReactComponent as ArrowIcon } from '@/assets/svg/dropdown_arrow.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

export type SelectOption<V = string> = {
  value: V;
  label: string;
  url?: string;
  disabled?: boolean;
};

type OptionValueType = number | string;
export type OptionType<
  T extends OptionValueType = OptionValueType
> = SelectOption<T>;

type Props<T extends OptionType> = {
  options: Array<T>;
  value?: Nullable<T>;
  onChange?: (selectedOption: Nullable<T>) => void;
};

function Select<T extends OptionType = OptionType>({
  options,
  onChange,
  value: selectedOption,
}: Props<T>) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect<T>({
    selectedItem: selectedOption,
    items: options,
    onSelectedItemChange: (changes) => {
      if (!onChange) return;

      onChange(changes.selectedItem ?? null);
    },
  });

  return (
    <Container>
      <ValueButton {...getToggleButtonProps()} isOpen={isOpen}>
        <SelectedItemLabel>{selectedItem?.label}</SelectedItemLabel>
        <Indicator>
          <ArrowIcon />
        </Indicator>
      </ValueButton>
      <Menu {...getMenuProps()}>
        {isOpen &&
          options.map((item, index) => (
            <MenuItem
              key={item.value}
              isActive={
                selectedItem ? selectedItem.value === item.value : false
              }
              isDisabled={item.disabled}
              {...(!item.disabled && getItemProps({ item, index }))}
            >
              {item.url && !item.disabled ? (
                <Link to={item.url}>
                  <Item>{item.label}</Item>
                </Link>
              ) : (
                <Item>{item.label}</Item>
              )}
            </MenuItem>
          ))}
      </Menu>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;
const ValueButton = styled.button<{ isOpen: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.isOpen ? `${colors.primary}` : `${colors.gray200}`};
  background-color: ${colors.white};
  transition: 0.3s;
}
`;
const SelectedItemLabel = styled.span`
  flex: 1;
  min-width: 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
`;

const Indicator = styled.div`
  margin-left: auto;
  padding-left: 10px;
`;
const Menu = styled.ul`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 6px;
  border-radius: 5px;
  overflow: hidden;
  max-height: 300px;
  overflow-y: auto;
  transition: 0.3s;
`;
const MenuItem = styled.li<{ isActive: boolean; isDisabled: boolean }>`
  padding: 10px 20px;
  font-size: 14px;
  flex-basis: 34px;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  color: ${({ isActive, isDisabled }) =>
    isActive ? colors.primary : isDisabled ? colors.gray300 : colors.black};
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  &:hover {
    color: ${({ isDisabled }) =>
      isDisabled ? colors.gray300 : colors.primary};
  }
`;

const Item = styled.span`
  display: block;
  width: 100%;
`;

export default Select;
