import React from 'react';
import styled, { css } from 'styled-components';

import {
  FETCH_STATUSES,
  generateNumberArray,
  ResourceType,
} from '@tager/web-core';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import PostCard from '@/components/PostCard';
import { PostShort } from '@/typings/model';
import { colors } from '@/constants/theme';
import PlaceholderCard from '@/components/PlaceholderCard';
import { StringFieldType } from '@/typings/common';

type Props = {
  posts: ResourceType<Array<PostShort>>;
  title?: StringFieldType;
};
function PostsSection({ posts, title }: Props) {
  if (posts.status === FETCH_STATUSES.SUCCESS && posts.data.length === 0) {
    return null;
  }

  return (
    <Container>
      <ContentContainer>
        {title ? <Title>{title}</Title> : null}
        <Row>
          {posts.status === FETCH_STATUSES.LOADING ? (
            <>
              {generateNumberArray(4).map((num) => (
                <PostWrapper key={num}>
                  <PlaceholderCard height={371} />
                </PostWrapper>
              ))}
            </>
          ) : posts.data && posts.status === FETCH_STATUSES.SUCCESS ? (
            <>
              {posts.data.slice(0, 4).map((post) => (
                <PostWrapper key={post.id}>
                  <PostCard variant="small" post={post} />
                </PostWrapper>
              ))}
            </>
          ) : null}
        </Row>
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div`
  background: ${colors.gray};
  padding: 50px 0;
`;

export const Title = styled.h2`
  display: block;
  font-size: 36px;
  line-height: 100%;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PostWrapper = styled.div`
  flex-basis: calc(25% - 20px);
  display: flex;
  ${media.laptop(
    css`
      flex-basis: calc(33.333% - 20px);
      &:nth-child(4) {
        display: none;
      }
    `
  )}
`;

export default PostsSection;
