import React from 'react';

import Consultant from '@/components/Consultant';
import useSettingItem from '@/hooks/useSettingItem';

function FilledConsultant() {
  const phoneNumberA1 = useSettingItem('CONTACTS_PHONE_A1');
  const phoneNumberMts = useSettingItem('CONTACTS_PHONE_MTS');
  const phoneNumberViber = useSettingItem('CONTACTS_VIBER');
  const viberText = useSettingItem('HEADER_VIBER_TEXT');
  const helperImage = useSettingItem('HELPER_IMAGE');
  const helperTitle = useSettingItem('HELPER_TEXT');
  const helperName = useSettingItem('HELPER_NAME');
  const helperPosition = useSettingItem('HELPER_POSITION');
  const helperText = useSettingItem('HELPER_QUOTE');
  const helperNote = useSettingItem('HELPER_NOTE');

  return (
    <Consultant
      title={helperTitle}
      viber={phoneNumberViber}
      viberText={viberText}
      mts={phoneNumberMts}
      a1={phoneNumberA1}
      name={helperName}
      position={helperPosition}
      text={helperText}
      note={helperNote}
      image={helperImage}
    />
  );
}

export default FilledConsultant;
