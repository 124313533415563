import React from 'react';
import styled from 'styled-components';

import MessengerIcon from '@/components/MessengerIcon';
import ContactsNote from '@/components/ContactsNote';
import { MessengerType } from '@/typings/common';

type PhonesByMessengerType = { [key in MessengerType]?: string };

type Props = PhonesByMessengerType & {
  text?: string;
  textBold?: string;
};

function Messengers({ text, textBold, telegram, viber, whatsapp }: Props) {
  const messengerOptions: Array<{
    messenger: MessengerType;
    phoneOrChannel: string | undefined;
  }> = [
    { messenger: 'telegram', phoneOrChannel: telegram },
    { messenger: 'viber', phoneOrChannel: viber },
    { messenger: 'whatsapp', phoneOrChannel: whatsapp },
  ];

  return (
    <Container>
      <Icons>
        {messengerOptions.map((option) => (
          <StyledMessengerIcon
            key={option.messenger}
            messenger={option.messenger}
            phoneOrChannel={option.phoneOrChannel}
          />
        ))}
      </Icons>
      {text || textBold ? (
        <ContactsNote text={text ?? ''} textBold={textBold} />
      ) : null}
    </Container>
  );
}

const StyledMessengerIcon = styled(MessengerIcon)`
  margin-right: 10px;
`;

const Container = styled.div`
  position: relative;
  height: 60px;
`;

const Icons = styled.div`
  display: inline-flex;
  justify-content: space-between;
`;

export default Messengers;
