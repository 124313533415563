import React from 'react';
import styled from 'styled-components';

import { colors } from '@/constants/theme';

type Props = {
  text: string;
  textBold?: string;
  className?: string;
};

function ContactsNote({ text, textBold, className }: Props) {
  return (
    <Content className={className}>
      {text}
      {textBold ? <TextBold>{textBold}</TextBold> : null}
    </Content>
  );
}

const Content = styled.span`
  height: 38px;
  line-height: 38px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  background: ${colors.gray100};
  white-space: nowrap;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  min-width: 100px;
  position: absolute;
  top: calc(100% + 11px);
  left: 0;

  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 11px 11px;
    border-color: transparent transparent ${colors.gray100} transparent;
    position: absolute;
    content: '';
    display: block;
    top: -11px;
    left: 20px;
  }
`;

const TextBold = styled.strong`
  color: ${colors.primary};
  margin-left: 4px;
`;

export default ContactsNote;
