import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styled from 'styled-components';

import { createMediaQuery } from '@tager/web-components';
import { useMedia } from '@tager/web-core';

import ProductCardPreview from '@/components/ProductCard/ProductCardPreview';
import { ProductPreviewType, ProductType } from '@/typings/model';
import { ReactComponent as SlideArrowIcon } from '@/assets/svg/slide-arrow.svg';
import { breakpoints } from '@/constants/theme';
import Link from '@/components/Link';
import { getCurrentProject } from '@/utils/common';

SwiperCore.use([Navigation]);

type Props = {
  products: Array<ProductType | ProductPreviewType>;
  index: number;
};

function ProductSwiper({ products, index }: Props) {
  const project = getCurrentProject();
  const isDesktop = useMedia(createMediaQuery({ min: breakpoints.desktop }));
  const isLaptop = useMedia(createMediaQuery({ max: breakpoints.laptop }));
  const isLaptopOnly = useMedia(
    createMediaQuery({ min: breakpoints.laptop, max: breakpoints.desktop })
  );

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Row project={project}>
      <NavButton className={`swiper-prev-${index}`} left>
        <SlideArrowIcon />
      </NavButton>
      <NavButton className={`swiper-next-${index}`} next>
        <SlideArrowIcon />
      </NavButton>
      {isMounted && (
        <Swiper
          slidesPerView={4}
          slidesPerGroup={4}
          speed={700}
          spaceBetween={10}
          loop={
            (isDesktop && products.length > 6) ||
            (isLaptop && products.length > 5) ||
            (isLaptopOnly && products.length > 4)
          }
          watchOverflow
          navigation={{
            nextEl: `.swiper-next-${index}`,
            prevEl: `.swiper-prev-${index}`,
          }}
          breakpoints={{
            [breakpoints.desktop]: {
              slidesPerView: 6,
              slidesPerGroup: 6,
            },
            [breakpoints.laptop]: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
          }}
        >
          {products?.map((product) => (
            <SwiperSlide key={product.id}>
              <Link to={`/products/${product.urlAlias}`}>
                <ProductCardPreview {...product} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Row>
  );
}

const Row = styled.div<{ project: string | undefined }>`
  position: relative;

  .swiper-container {
    padding: 20px 0;
  }

  .swiper-slide {
    height: ${({ project }) => (project === 'SAMOVARKIN' ? '260px' : '360px')};
  }
`;

const NavButton = styled.button<{ next?: boolean; left?: boolean }>`
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: ${(props) => (props.left ? '40px' : '9px')};
  top: -40px;
  z-index: 10;

  &:hover {
    opacity: 0.5;
  }

  svg {
    transform: ${(props) => (props.next ? 'rotate(180deg)' : 'rotate(0deg)')};
    width: 14px;
    height: 24px;
  }
`;

export default ProductSwiper;
