import React from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { PlainPicture } from '@/components/Picture';
import ContactsNote from '@/components/ContactsNote';
import PhoneNumber from '@/components/PhoneNumber';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { StringFieldType } from '@/typings/common';

type Props = {
  title?: StringFieldType;
  viber?: StringFieldType;
  viberText?: StringFieldType;
  mts?: StringFieldType;
  a1?: StringFieldType;
  name?: StringFieldType;
  position?: StringFieldType;
  text?: StringFieldType;
  note?: StringFieldType;
  image?: Nullable<ThumbnailType>;
};

function Consultant({
  title,
  viber,
  viberText,
  mts,
  a1,
  name,
  position,
  text,
  note,
  image,
}: Props) {
  return (
    <Container>
      <Row>
        {title && <Title>{title}</Title>}

        <Phones>
          {viber && (
            <PhonesColumn>
              <PhoneNumber
                operator="viber"
                phoneNumber={viber}
                variant="big-bold"
                text={viberText ?? ''}
              />
            </PhonesColumn>
          )}
          {(a1 || mts) && (
            <PhonesColumn>
              {a1 && (
                <PhonesWrapper>
                  <PhoneNumber
                    operator="a1"
                    phoneNumber={a1}
                    variant="big-bold"
                  />
                </PhonesWrapper>
              )}
              {mts && (
                <PhonesWrapper>
                  <PhoneNumber
                    operator="mts"
                    phoneNumber={mts}
                    variant="big-bold"
                  />
                </PhonesWrapper>
              )}
            </PhonesColumn>
          )}
        </Phones>

        {(name || position || text) && (
          <Specialist>
            <SpecialistInfo>{name}</SpecialistInfo>
            <SpecialistInfo>{position}</SpecialistInfo>
            {text && <StyledContactsNote text={text} />}
          </Specialist>
        )}

        <Note>{note}</Note>
      </Row>
      {image && (
        <StyledRow>
          <PlainPicture
            {...convertThumbnailToPictureProps(image)}
            alt={position ?? 'Consultant'}
          />
        </StyledRow>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled(Row)`
  flex: 1;
  justify-content: center;
  margin: 0 0 0 15px;
  ${media.mobile(css`
    align-items: center;
    margin: 0 0 15px 0;
  `)};
`;

const Title = styled.span`
  margin-bottom: 25px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  white-space: pre-line;
`;

const Phones = styled.div``;

const PhonesColumn = styled.div`
  display: flex;
  margin-bottom: 30px;
  ${media.mobile(css`
    flex-direction: column;
    margin-bottom: 20px;
  `)}
`;

const PhonesWrapper = styled.div`
  margin-right: 50px;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.mobile(css`
    margin: 0 0 25px 0;
    &:last-child {
      margin-bottom: 0;
    }
  `)}
`;

const Specialist = styled.div`
  margin-bottom: 45px;
`;

const SpecialistInfo = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const StyledContactsNote = styled(ContactsNote)`
  white-space: pre-line;
  height: auto;
  line-height: 24px;
  font-size: 14px;
  padding: 15px;
  top: 25px;
  position: relative;
  display: inline-block;
  color: ${colors.gray500};
`;

const Note = styled.span`
  display: block;
  color: ${colors.gray500};
  font-size: 13px;
`;

export default Consultant;
