import React from 'react';
import styled from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';
import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { Picture } from '@/components/Picture';
import Placeholder from '@/assets/images/placeholder.png';
import { normalizePrice } from '@/utils/common';

type Props = {
  name: string;
  image: Nullable<ThumbnailType>;
  category?: string;
  price: Nullable<number>;
  discountPrice: Nullable<number>;
};

function DefaultProductCardPreview({
  image,
  category,
  name,
  price,
  discountPrice,
}: Props) {
  return (
    <Container>
      <ImageWrapper>
        {image ? (
          <Picture
            {...convertThumbnailToPictureProps(image)}
            alt={name}
            useSpinner
          />
        ) : (
          <Picture src={Placeholder} />
        )}
      </ImageWrapper>
      {category ? <Category>{category}</Category> : null}
      <Name>{name}</Name>
      {!price ? null : (
        <PriceWrapper>
          {discountPrice !== price && discountPrice ? (
            <OldPrice>{normalizePrice(price)} руб.</OldPrice>
          ) : null}
          <Price>{normalizePrice(discountPrice ?? price)} руб.</Price>
        </PriceWrapper>
      )}
    </Container>
  );
}

const Name = styled.span`
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
`;

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  padding: 15px;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  }

  &:hover ${Name} {
    color: ${colors.primary};
  }

  &:before {
    content: '';
    border: 1px solid ${colors.gray100};
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
`;

const ImageWrapper = styled.div`
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 180px;
  }
`;

const Category = styled.span`
  font-size: 13px;
  color: ${colors.gray500};
  display: block;
  line-height: 100%;
  margin-bottom: 5px;
`;

const PriceWrapper = styled.div`
  margin-top: auto;
`;

const OldPrice = styled.span`
  color: ${colors.gray500};
  font-size: 16px;
  font-weight: 500;
  text-decoration: line-through;
  display: block;
  margin-bottom: 3px;
`;

const Price = styled.span`
  font-size: 24px;
  line-height: 100%;
  font-weight: 600;
`;

export default DefaultProductCardPreview;
