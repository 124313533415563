import React from 'react';

import { Nullable } from '@tager/web-core';
import { ThumbnailType } from '@tager/web-modules';

import { getCurrentProject } from '@/utils/common';

import {
  SamovarkinProductCardPreview,
  DefaultProductCardPreview,
} from './components';

export type ProductCardPreviewProps = {
  name: string;
  image: Nullable<ThumbnailType>;
  category?: string;
  price: Nullable<number>;
  discountPrice: Nullable<number>;
};

function ProductCardPreview({ ...cardArgs }: ProductCardPreviewProps) {
  const project = getCurrentProject();

  switch (project) {
    case 'SAMOVARKIN':
      return <SamovarkinProductCardPreview {...cardArgs} />;
    default:
      return <DefaultProductCardPreview {...cardArgs} />;
  }
}

export default ProductCardPreview;
